import applicationActions from "./applicationActions";
import blockChainActions from "./blockChainActions";
import authActions from "./authActions";
import stakingActions from "./stakingActions";

const allActions = {
  applicationActions,
  blockChainActions,
  authActions,
  stakingActions,
};

export default allActions;
