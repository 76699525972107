import Web3 from 'web3';
import { ethers,BigNumber } from 'ethers';
import { STAKINGADDRESS, TOKENABI, TOKENADDRESS, STAKINGABI, UNIT_LEVEL } from 'config/constants';
import actions from "../actions";
import {
  BLOCKCHAIN_ACTION,
  LOAD_BLOCKCHAIN,
  SET_STAKE_OR_UNSTAKE_AMOUNT,
} from "../types";

import {
  APPROVE,
  CLAIM,
  STAKING,
  UNSTAKING,
} from "../../enums/blockchainActions";
import { toast } from "react-toastify";
const authorizeValue =
    "115792089237316195423570985008687907853269984665640564039457584007913129639935";

const toWei = (amount) => BigNumber.from(amount).mul(BigNumber.from(UNIT_LEVEL));

const approve = () => async (dispatch, getState) => {
    try {
        const state = getState();
        const { walletAddress, isAuthenticated } = state.auth;

        if(!isAuthenticated)
        {
           dispatch(actions.applicationActions.updateModalStep(1));
           dispatch(actions.applicationActions.updateModalState(true));
        }
        const { web3Provider } = state.blockChain;

        const signer = await web3Provider.getSigner();
        const contract = new ethers.Contract(TOKENADDRESS, TOKENABI, signer);
       
        dispatch(actions.applicationActions.setLoading(true));
        dispatch({
           type: BLOCKCHAIN_ACTION,
           payload: { blockChainAction: APPROVE },
           });
        
        let { hash } = await contract.approve(STAKINGADDRESS, authorizeValue);
        
        await web3Provider.waitForTransaction(hash);

        dispatch(actions.applicationActions.setLoading(false));
        dispatch(actions.stakingActions.loadUserDetails())
    } catch (error) {
        console.error('Error during the approval process:', error);
        dispatch(actions.applicationActions.setLoading(false));
    }
};

const loadUserDetails = () => async (dispatch, getState) => {
    try {
        const state = getState();
        const { walletAddress } = state.auth;
        const { web3Provider } = state.blockChain;

        const signer = await web3Provider.getSigner();
        const contract = new ethers.Contract(TOKENADDRESS, TOKENABI, signer);
        let spendingLimit = await contract.allowance(walletAddress, STAKINGADDRESS);
        const balance = await contract.balanceOf(walletAddress);
        
        const stakingContract = new ethers.Contract(STAKINGADDRESS, STAKINGABI, signer);
        const userDetails = await stakingContract.getUserDetails(walletAddress);
        const totalStaked = userDetails && userDetails["0"] && userDetails["0"] / UNIT_LEVEL;
        const totalClaimed = userDetails && userDetails["7"] && userDetails["7"] / UNIT_LEVEL;

        let totalRewards = "", nextClaimableReward, nextRewardDate = "";

        try {
            totalRewards = await stakingContract.getTotalRewards(walletAddress);
            nextClaimableReward = await stakingContract.getNextClaimableReward(walletAddress);
            nextRewardDate = await stakingContract.nextClaimableDate(walletAddress);
        } catch (err) {
            console.info("Previous request error is controlled");
        }

        const userData = {
            totalStaked: totalStaked && parseFloat(totalStaked).toFixed(7),
            totalClaimed: totalClaimed && parseFloat(totalClaimed).toFixed(7),
            totalRewards: totalRewards && parseFloat(totalRewards).toFixed(7),
            nextClaimableReward: nextClaimableReward && parseFloat(nextClaimableReward).toFixed(7),
            nextRewardDate: nextRewardDate && new Date(nextRewardDate),
            walletAddress: walletAddress,
        };

        console.log(balance)

        dispatch({
            type: "SET_USER_DETAILS",
            payload: {
                totalStaked,
                totalClaimed,
                totalRewards,
                nextClaimableReward,
                nextRewardDate,
                walletAddress,
                allowance: spendingLimit,
                balance
            },
        });

    } catch (error) {
        console.error('Error during the user details loading process:', error);
    }
};

const allowance = () => async (dispatch, getState) => {
    try {
        const state = getState();
        const { walletAddress } = state.auth;
        const { web3Provider } = state.blockChain;

        const signer = await web3Provider.getSigner();
        const contract = new ethers.Contract(TOKENADDRESS, TOKENABI, signer);
        let spendingLimit = await contract.allowance(walletAddress, STAKINGADDRESS);
        console.log(`Spending limit: ${spendingLimit}`);
        dispatch(loadUserDetails())
    } catch (error) {
        console.error('Error during the allowance process:', error);
    }
};

const stakeTokens = () => async (dispatch, getState) => {
    try {
        // Retrieve necessary data from state
        const state = getState();

        const { isAuthenticated } = state.auth;

        if(!isAuthenticated)
        {
           dispatch(actions.applicationActions.updateModalStep(1));
           dispatch(actions.applicationActions.updateModalState(true));
        }

        const { stakeOrUnStakeAmount, web3Provider, balance } = getState().blockChain;
        const normalizedBalance = BigNumber.from(balance);
        const stakeAmountWithWei = toWei(stakeOrUnStakeAmount);

        console.log(normalizedBalance.toString())
        console.log(stakeAmountWithWei.toString())

        // Check for sufficient balance
        if (normalizedBalance.lt(stakeAmountWithWei)) {
            const tokenShortage = stakeAmountWithWei.sub(normalizedBalance);
            toast.error(`Unable to stake - Transaction Error. You are short of ${tokenShortage.div(BigNumber.from(UNIT_LEVEL)).toString()} tokens.`);
            return; 
        }

        // Dispatch loading action and set blockchain action
        dispatch(actions.applicationActions.setLoading(true));
        dispatch({ type: BLOCKCHAIN_ACTION, payload: { blockChainAction: STAKING } });

        console.log(stakeAmountWithWei.toString())

        // Create contract instance and stake tokens
        const signer = await web3Provider.getSigner();
        const stakingContract = new ethers.Contract(STAKINGADDRESS, STAKINGABI, signer);
        const { hash } = await stakingContract.stake(stakeAmountWithWei);

        // Wait for the transaction to be confirmed
        await web3Provider.waitForTransaction(hash);
        
        // Dispatch actions after successful transaction
        dispatch(actions.applicationActions.setLoading(false));
        dispatch(actions.stakingActions.loadUserDetails());
    } catch (error) {
        dispatch(actions.applicationActions.setLoading(false));
        toast.error(`Unable to stake - Transaction Error. ${error?.message}`);
        console.log(error)
        // console.error(`${ERROR_DURING_STAKING} ${error}`);
    }
};


const unStakeTokens = () => async (dispatch, getState) => {
    try {
        const state = getState();
         const { isAuthenticated } = state.auth;

         console.log(isAuthenticated)

        if(!isAuthenticated)
        {
           dispatch(actions.applicationActions.updateModalStep(1));
           dispatch(actions.applicationActions.updateModalState(true));
        }

        const { stakeOrUnStakeAmount, web3Provider, totalStaked } = getState().blockChain;
        const normalizedTotalStaked = toWei(Math.floor(totalStaked));
        const unStakeAmountWithWei = toWei(stakeOrUnStakeAmount);

        // Check for sufficient staked balance
        if (normalizedTotalStaked.lt(unStakeAmountWithWei)) {
            const tokenShortage = unStakeAmountWithWei.sub(normalizedTotalStaked);
            toast.error(`Unable to unstake - Transaction Error. You are short of ${tokenShortage.div(BigNumber.from(UNIT_LEVEL)).toString()} tokens.`);
            return;
        }

        // Dispatch loading action and set blockchain action
        dispatch(actions.applicationActions.setLoading(true));
        dispatch({
            type: BLOCKCHAIN_ACTION,
            payload: { blockChainAction: UNSTAKING },
        });

        // Create contract instance and unstake tokens
        const signer = await web3Provider.getSigner();
        const stakingContract = new ethers.Contract(STAKINGADDRESS, STAKINGABI, signer);
        const { hash } = await stakingContract.unstake(unStakeAmountWithWei.toString());

        // Wait for the transaction to be confirmed
        await web3Provider.waitForTransaction(hash);
        
        // Dispatch actions after successful transaction
        dispatch(actions.applicationActions.setLoading(false));
        dispatch(actions.stakingActions.loadUserDetails());
    } catch (error) {
        dispatch(actions.applicationActions.setLoading(false));
        console.error('Error during the unstaking process:', error);
        
    }
};


const claimReward = () => async (dispatch, getState) => {

    try {
        const { stakeOrUnStakeAmount, web3Provider } = getState().blockChain;
        const signer = await web3Provider.getSigner();
        dispatch(actions.applicationActions.setLoading(true));
         dispatch({
           type: BLOCKCHAIN_ACTION,
           payload: { blockChainAction: CLAIM },
           });
        const stakingContract = new ethers.Contract(STAKINGADDRESS, STAKINGABI, signer);
        const { hash } = await stakingContract.claim()
        
        await web3Provider.waitForTransaction(hash);
        dispatch(actions.applicationActions.setLoading(false));
        dispatch(actions.stakingActions.loadUserDetails())
    } catch (error) {
        dispatch(actions.applicationActions.setLoading(false));
        console.error('Error during the unstaking process:', error);
    }
};

const stakingActions = {
    stakeTokens,
    allowance,
    approve,
    loadUserDetails,
    unStakeTokens,
    claimReward
};

export default stakingActions;
