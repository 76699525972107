import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Modal } from "react-bootstrap";
import actions from "../store/actions";
import LoginModal from "./modals/LoginModal";
import VerifyModal from "./modals/VerifyModal";
import ConnectWalletModal from "./modals/ConnectWalletModal";
import MetaMaskModal from "./modals/MetaMaskModal";
import ModalUnstake from "./modals/ModalUnstake";
import ChooseHowConnect from "./modals/ChooseHowConnectModal";

const ModalDialogs = () => {
  const [email, setEmail] = useState("");
  const [verificationCode, setVerificationCode] = useState("");
  const { modalShow, modalStep, error } = useSelector(
    (state) => state.application
  );
  const dispatch = useDispatch();

  return (
    <>
      <Modal
        show={modalShow}
        onHide={() =>
          dispatch(actions.applicationActions.updateModalState(false))
        }
        backdrop="static"
        keyboard={false}
        id="onboardModal"
        style={{ transform: "translate(0, 25%)" }}
      >
        {modalStep === 1 && (
          <LoginModal email={email} setEmail={setEmail} error={error} />
        )}
        {modalStep === 2 && (
          <VerifyModal
            setVerificationCode={setVerificationCode}
            verificationCode={verificationCode}
            email={email}
            error={error}
          />
        )}
        {modalStep === 3 && <ConnectWalletModal />}
        {modalStep === 4 && <MetaMaskModal />}
        {modalStep === 5 && <ChooseHowConnect />}
        {modalStep === 6 && <ModalUnstake />}
      </Modal>
    </>
  );
};

export default ModalDialogs;
