import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { BrowserRouter } from "react-router-dom";

import { createWeb3Modal, defaultConfig } from "@web3modal/ethers5/react";

const configuration = {
  chains: [
    {
      chainId: 56, // BSC Mainnet Chain ID
      name: "Binance Smart Chain Mainnet",
      currency: "BNB", // Binance Coin
      explorerUrl: "https://bscscan.com/", // BSC block explorer
      rpcUrl: "https://bsc-dataseed.binance.org/", // BSC RPC URL
    },
  ],
  NETWORKS: {
    BSC: {
      chainId: "0x38", // Hexadecimal chainId for BSC Mainnet
      chainName: "Binance Smart Chain Mainnet",
      nativeCurrency: {
        name: "BNB", // Binance Coin
        symbol: "BNB", // Binance Coin symbol
        decimals: 18, // Standard decimals
      },
      rpcUrls: ["https://bsc-dataseed.binance.org/"], // BSC RPC URLs
      blockExplorerUrls: ["https://bscscan.com/"], // BSC block explorer URLs
    },
  },
};

const metadata = {
  name: "SilverStonks",
  description: "SilverStonks",
  url: "http://localhost:3000/",
  icons: ["https://silverstonks.io/assets/img/logo.svg"],
};

createWeb3Modal({
  ethersConfig: defaultConfig({ metadata }),
  chains: configuration.chains,
  projectId: "7f8dfa6d6098111cb211298498b8f2c2",
});

ReactDOM.render(
  <>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </>,
  document.getElementById("root")
);
