import {
  LOAD_BLOCKCHAIN,
  BLOCKCHAIN_ERROR,
  SET_STAKE_OR_UNSTAKE_AMOUNT,
  BLOCKCHAIN_ACTION,
  LOAD_WEB3_PROVIDER,
} from "../types";

const initialState = {
  blockChainLoaded: false,
  web3: null,
  stakingContract: null,
  tokenContract: null,
  totalClaimed: "",
  nextClaimableReward: "",
  liveEarned: "",
  claimDate: "",
  totalStaked: "",
  walletProvider: null,
  connectedWallet: null,
  approvedAmount: 0,
  stakeOrUnStakeAmount: 0,
  connector: null,
  blockChainAction: "",
  web3Provider: null,
  balance:0
};

export default function blockChainReducer(state = initialState, action) {
  switch (action.type) {
    case SET_STAKE_OR_UNSTAKE_AMOUNT: {
      return { ...state, stakeOrUnStakeAmount: action.payload.amount };
    }
    case LOAD_BLOCKCHAIN:
      return {
        ...state,
        blockChainLoaded: true,
        web3: action.payload.web3,
        stakingContract: action.payload.stakingContract,
        tokenContract: action.payload.tokenContract,
        connector: action.payload.connector,
      };
    case "SET_WALLET_PROVIDER":
      return {
        ...state,
        walletProvider: action.payload.walletProvider,
      };
    case BLOCKCHAIN_ERROR:
      return {
        ...state,
        blockChainLoaded: false,
      };
    case "SET_USER_DETAILS":
      return {
        ...state,
        totalStaked:
          action.payload.totalStaked &&
          parseFloat(action.payload.totalStaked).toFixed(7),
        totalClaimed: action.payload.totalClaimed,
        totalRewards: action.payload.totalRewards,
        nextClaimableReward: action.payload.nextClaimableReward,
        nextRewardDate: action.payload.nextRewardDate,
        approvedAmount: action.payload.allowance,
        balance:action.payload.balance
      };
    case BLOCKCHAIN_ACTION:
      return { ...state, blockChainAction: action.payload.blockChainAction };
    case "SET_CONNECTED_WALLET":
      return { ...state, connectedWallet: action.payload.connectedWallet };
    case "LOGOUT_USER_BLOCKCHAIN":
      return {
        ...state,
        totalClaimed: null,
        liveEarned: null,
        claimDate: null,
        nextClaimableReward: null,
        totalStaked: null,
      };
    case LOAD_WEB3_PROVIDER: {
      console.log(action);
      return {
        ...state,
        web3Provider: action.payload,
      };
    }

    default:
      return state;
  }
}
