import React from "react";

export default function TutorialSection() {
	return (
		<section className="tutorial" id="tutorial">
			<div className="video-description">
				<div className="title-description">
					<div className="title">
						<b>How to Stake Your SSTX Tokens</b>
					</div>
					<div className="description">
						Follow the steps in this video to stake your SSTX tokens
					</div>
				</div>
				<div className="video-tutorial text-right">
					<iframe
						src="https://www.youtube.com/embed/9F-C9aTv0m4"
						title="YouTube video player"
						frameborder="0"
						allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
						allowfullscreen
					/>
				</div>
			</div>
			<div className="container">
				<a href="#stake" target="_self">
					<div className="memberships">
						<h4 className="title w-100 d-flex align-items-center justify-content-end">
							<img src="/assets/Arrows.png" alt="arrow" />
							<b>MEMBERSHIPS</b>
						</h4>
						<img src="/assets/silverstonks-memberships.png" alt="membership" />
						<div className="bottom mt-4 w-100 d-flex align-items-center justify-content-end">
							<img
								src="/assets/silversotnks-staking-details.png"
								alt="details"
							/>
						</div>
					</div>
				</a>
			</div>
		</section>
	);
}
