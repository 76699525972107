import React from "react";
import ModalDialogs from "./ModalDialogs";
import Stakeform from "./Stakeform";
import LeaderBoard from "./LeaderBoard";
import { useSelector } from "react-redux";
import { getLang } from "../i18n";

const Main = () => {
  const { isAuthenticated, walletAddress } = useSelector((state) => state.auth);
  const lang = getLang();
  localStorage.clear();
  localStorage.setItem('lang', lang);
  return (
    <>
      <ModalDialogs />
      <section className="stake" id="stake">
        <div disabled={"disabled"} className="container">
          <div className="row my-5">
            <div className="col-md-6">
              <Stakeform />
            </div>
            <div className="col-md-6">
              <LeaderBoard isAuthenticated={isAuthenticated} selectedAddress={walletAddress}/>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Main;
