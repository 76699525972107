import Loader from "react-loader-spinner";
import { useSelector } from "react-redux";
import { APPROVE, CLAIM, STAKING, UNSTAKING } from "../enums/blockchainActions";

const LoaderComponent = () => {
  const { blockChainAction, stakeOrUnStakeAmount } = useSelector(
    (state) => state.blockChain
  );
  let message = "";
  switch (blockChainAction) {
    case STAKING:
      message = `Staking ${stakeOrUnStakeAmount} SSTX Tokens`;
      break;
    case UNSTAKING:
      message = `UnStaking ${stakeOrUnStakeAmount} SSTX Tokens`;
      break;
    case APPROVE:
      message = `Approving Wallet`;
      break;
    case CLAIM:
      message = `Claiming SSTX Tokens...`;
      break;
  }
  return (
    <div className="" style={{ height: "400px" }}>
      <Loader type="Bars" color="#28a745" height={100} width={100} />
      <h3>{message}</h3>
    </div>
  );
};

export default LoaderComponent;
