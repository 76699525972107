import { useWeb3ModalAccount, useWeb3ModalProvider } from '@web3modal/ethers5/react';
import { formatWalletAddress } from '../utils';
import './AuthorizeButton.css';
import { LOAD_WALLET_ADDRESS, LOAD_WEB3_PROVIDER } from 'store/types';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { ethers } from 'ethers';
import actions from "../store/actions";


const AuthorizeButton = ({ walletAuthorized, onClickAuthorizeBehavior, walletAddress }) => {

    const dispatch = useDispatch();
    const { isConnected, address, chainId } = useWeb3ModalAccount();
     const { walletProvider } = useWeb3ModalProvider();

    useEffect(() => {
        if (isConnected) {
            dispatch({ type: LOAD_WALLET_ADDRESS, payload: address });
            const web3Provider = new ethers.providers.Web3Provider(walletProvider);
            dispatch({ type: LOAD_WEB3_PROVIDER, payload: web3Provider });
            dispatch(actions.stakingActions.loadUserDetails());
        } else {
        }
    }, [isConnected, address]);

    console.log(isConnected,address,chainId)


    const AuthorizedInfo = () => (
        <div className="leftdivauthorized">
            You have authorized wallet <span>{formatWalletAddress(walletAddress)}</span>.
        </div>
    );

    const UnauthorizedInfo = () => (
        <>
            <div className="col-xs-12 col-sm-8">
                Before staking, you need to authorize your wallet for allowing transactions.
            </div>
            <div className="col-xs-6 col-md-4 text-md-center">
                <button
                    className="btn btn-success m-0"
                    onClick={async () => await onClickAuthorizeBehavior()} >
                    Authorize
                </button>
            </div>
        </>
    );

    return (
        <div className="abcontainer row mb-4">
            {walletAuthorized ? <AuthorizedInfo /> : <UnauthorizedInfo />}
        </div>
    );
}

export default AuthorizeButton