import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

const resources = {
  en: {
    translation: require('./locales/en.json'),
  },
  es: {
    translation: require('./locales/es.json'),
  },
}

export function getLang() {
  return localStorage.getItem('lang') || 'en'
}

i18n.use(initReactI18next).init({
  resources,
  lng: getLang(),
  interpolation: {
    escapeValue: false,
  },
})

export function changeLanguage(lang) {
  if (lang) {
    localStorage.setItem('lang', lang)
    i18n.changeLanguage(lang)
  }
}

export default i18n
