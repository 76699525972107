const config = require("./sstxconfig.json");

// Application constants + thin wrapper over the sstxconfig.json file
// Add application-wide constants here to prevent misconfiguration

export const APIURL = config.ENV === "prod" ? config.API_URL_PROD : config.API_URL_LOCAL;
export const ACTIVENET = config.ACTIVENET;
export const THRESHOLD_LEVEL_1 = config.THRESHOLD_LEVEL_1;
export const UNIT_LEVEL = config.UNIT_LEVEL;
export const REFRESH_DELAY = 60000;
export const TOKENADDRESS = ACTIVENET === "main" ? config.TOKEN_ADDRESS_MAINNET : config.TOKEN_ADDRESS_TESTNET;
export const TOKENABI = ACTIVENET === "main" ? config.TOKEN_ABI_MAINNET : config.TOKEN_ABI_TESTNET;
export const STAKINGADDRESS = ACTIVENET === "main" ? config.STAKING_ADDRESS_MAINNET : config.STAKING_ADDRESS_TESTNET;
export const STAKINGABI = ACTIVENET === "main" ? config.STAKING_ABI_MAINNET : config.STAKING_ABI_TESTNET;
