import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "./LoaderComponent";
import actions from "../store/actions";
import "./Stakeform.css";
import ClaimButton from "./ClaimButton";
import AuthorizeButton from "./AuthorizeButton";
import { SET_STAKE_OR_UNSTAKE_AMOUNT } from "../store/types";
import { THRESHOLD_LEVEL_1, UNIT_LEVEL } from "../config/constants";

const Stakeform = () => {
  const [stakeValue, setStakeValue] = useState("");
  const [action, setAction] = useState("");
  const [error, setError] = useState("");

  const dispatch = useDispatch();
  const {
    totalClaimed,
    nextClaimableReward,
    nextRewardDate,
    totalStaked,
    approvedAmount
  } = useSelector((state) => state.blockChain);
  const { loading, advice } = useSelector((state) => state.application);


  const { isAuthenticated, walletAuthenticated, walletAddress } = useSelector(
    (state) => state.auth
  );

  const checkStatus = () => {
    dispatch(actions.blockChainActions.getUserDetails());
    if (isAuthenticated && walletAddress) {
      return true;
    } else if (isAuthenticated && !walletAuthenticated) {
      dispatch(actions.applicationActions.updateModalStep(3));
      dispatch(actions.applicationActions.updateModalState(true));
      return false;
    } else {
      dispatch(actions.applicationActions.updateModalStep(1));
      dispatch(actions.applicationActions.updateModalState(true));
      return false;
    }
  };

  // Check if unstake value matches with smart contract threshold level
  const checkUnstakeLevel = () => {
    let error = "";
    let status = false;
    console.log(
      "TOTAL STAKED _ STAKE VALUE",
      totalStaked,
      stakeValue,
      totalStaked - stakeValue
    );
    if (totalStaked - stakeValue === 0) {
      console.log("TOTAL STAKED _ STAKE VALUE", totalStaked - stakeValue);
      status = true;
      return { status };
    }
    if (totalStaked - stakeValue > THRESHOLD_LEVEL_1) {
      status = true;
      return { status };
    }
    if (totalStaked - stakeValue < THRESHOLD_LEVEL_1) {
      error = "You need to unstake a lesser amount, or unstake all tokens";
      return { error };
    }
  };

  // Check if stake value matches with smart contract threshold level
  const checkStakingLevel = () => {
    let error = "";
    let status = false;
    if (totalStaked + stakeValue >= THRESHOLD_LEVEL_1) {
      status = true;
      return { status };
    }
    if (totalStaked + stakeValue < THRESHOLD_LEVEL_1) {
      error = "Tokens are less than Membership threshold";
      return { error };
    }
  };

  const nextAvailableClaimDate = () => {
    const currentDate = new Date();
    const rewardDate = !!nextRewardDate
      ? new Date(nextRewardDate * 1000)
      : null;

    if (!!rewardDate && rewardDate > currentDate) {
      const day = rewardDate.getDate().toString().padStart(2, "0");
      const month = (rewardDate.getMonth() + 1).toString().padStart(2, "0");
      const year = rewardDate.getFullYear().toString();
      return `${day}/${month}/${year}`;
    } else {
      const day = currentDate.getDate().toString().padStart(2, "0");
      const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
      const year = currentDate.getFullYear().toString();
      return !!rewardDate ? `${day}/${month}/${year}` : "";
    }
  };

  const totalSSTXStaked = () => {
    return !!totalStaked ? parseFloat(totalStaked).toFixed(7) : "";
  };

  const nextClaimableSSTXAmount = () => {
    return !!nextClaimableReward
      ? (parseFloat(nextClaimableReward) / UNIT_LEVEL).toFixed(7)
      : "";
  };

  const totalSSTXEarned = () => {
    return !!totalClaimed ? parseFloat(totalClaimed).toFixed(7) : "";
  };

  if (loading) {
    return (
      <>
        <Loader action={action} />
      </>
    );
  }

  return (
    <>
      <section className="stake-form container">
        <div className="row">
          <div className="stake-form-content col">
            <AuthorizeButton
              walletAuthorized={approvedAmount > 0}
              // walletAuthorized={walletAuthorized}
              onClickAuthorizeBehavior={() =>
                dispatch(actions.stakingActions.approve())
              }
              walletAddress={walletAddress || "............................."}
            />
            <h2>Stake Your SSTX</h2>
            <p>Enter the amount you wish to stake.</p>
          </div>
        </div>
        <div className="form">
          {/* ELEMENTO UNO DEL FORM */}
          <div className="form-group row no-gutters align-items-end">
            <div className="col-xs-12 col-md-6 col-lg-7">
              <input
                type="number"
                min="0"
                placeholder="0"
                onChange={(e) =>
                  dispatch({
                    type: SET_STAKE_OR_UNSTAKE_AMOUNT,
                    payload: { amount: e.target.value }
                  })
                }
                //
                className="form-control w-100"
              />
              {error && <p style={{ color: "red" }}>{error}</p>}
            </div>
            <div className="col-xs-12 col-md-6 col-lg-5">
              <div className="row no-gutters ml-md-2  d-flex justify-content-around">
                <button
                  className="btn btn-success col-xs-6 col-sm-6 col-md-5 w-100 d-flex justify-content-center"
                  onClick={() =>
                    approvedAmount > 0
                      ? dispatch(actions.stakingActions.stakeTokens())
                      : dispatch(actions.stakingActions.approve())
                  }
                >
                  Stake
                </button>

                <button
                  className="btn btn-secondary col-xs-6 col-sm-6 col-md-5 w-100 d-flex justify-content-center"
                  onClick={() => dispatch(actions.stakingActions.unStakeTokens())}
                >
                  Unstake
                </button>
              </div>
            </div>
          </div>

          {/* ELEMENTO DOS DEL FORM */}
          <div className="amount form-group row no-gutters">
            <div className="col-12 mb-3">
              <label>Total SSTX Staked:</label>
              <input
                type="text"
                className="form-control w-100"
                placeholder="0"
                disabled="disabled"
                value={totalSSTXStaked()}
              />
            </div>
          </div>

          {/* ELEMENTO TRES DEL FORM */}
          <div className="form-group row no-gutters align-items-end">
            <div className="col-xs-12 col-md-6 col-lg-7">
              <label>Next Available Claim Time:</label>
              <input
                type="text"
                className="form-control mr-1 w-100"
                disabled="disabled"
                value={nextAvailableClaimDate()}
                placeholder="dd/mm/yyyy"
              />
            </div>
            <div className="col-xs-12 col-md-6 col-lg-4 ml-md-2">
              <ClaimButton
                nextRewardDate={nextRewardDate}
                onClickClaimBehavior={() =>
                  dispatch(actions.stakingActions.claimReward())
                }
              />
            </div>
          </div>

          {/* ELEMENTO CUATRO DEL FORM */}
          <div className="earned">
            <div className="form-group row no-gutters align-items-end">
              <div className="col-xs-12 col-md-6 col-lg-7">
                <label>Next Claimable SSTX Amount</label>
                <input
                  type="text"
                  className="form-control mr-1 w-100"
                  disabled="disabled"
                  placeholder="0.0000000"
                  value={nextClaimableSSTXAmount()}
                />
              </div>
              <div className="col-xs-12 col-md-5 col-lg-4 ml-md-2">
                <button
                  className="btn btn-outline-success w-100"
                  onClick={() =>
                    dispatch(actions.stakingActions.loadUserDetails())
                  }
                >
                  Refresh
                </button>
              </div>
            </div>
          </div>

          {/* ELEMENTO CINCO DEL FORM */}
          <div className="earned form-group row no-gutters">
            <div className="col-12">
              <label>Total SSTX Earned:</label>
              <input
                type="text"
                className="form-control mr-1 w-100"
                placeholder="0.0000000"
                disabled="disabled"
                value={totalSSTXEarned()}
              />
            </div>
          </div>
          <div className="calcMessage">
            You can claim your reward after the first week.
          </div>
        </div>
      </section>
    </>
  );
};

export default Stakeform;
