import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Footer from "./Footer";
import Header from "./Header";
import BuySection from "./BuySection";
import Main from "./Main";
import actions from "../store/actions";
import "../static/scss/main.scss";
import TutorialSection from "./TutorialSection";

const HomePage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.applicationActions.getUserRankings());
    dispatch(actions.applicationActions.setModal());
  }, [dispatch]);

  return (
    <>
      <Header />
      <ToastContainer />
      <Main />
      <TutorialSection />
      <BuySection />
      
      <Footer />
    </>
  );
};

export default HomePage;
