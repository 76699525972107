import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Logo from "../static/images/logo.svg";
import { FaBars } from "react-icons/fa";
import actions from "../store/actions";

const Header = () => {
	const [scrolled, setScrolled] = useState(false);
	const { walletAddress, isAuthenticated } = useSelector(
		(state) => state.auth
	);
	// eslint-disable-next-line no-unused-vars
	const [text, setText] = useState("");
	const dispatch = useDispatch();
	const handleScroll = () => {
		const offset = window.scrollY;
		if (offset > 100) {
			setScrolled(true);
		} else {
			setScrolled(false);
		}
	};

	window.addEventListener("scroll", handleScroll);
	let x = ["nav-top"];
	if (scrolled) {
		x.push("scrolled stick");
	}

	useEffect(() => {
		window.addEventListener("scroll", handleScroll);
	}, []);

	/*const onClickLogIn = () => {
    dispatch(actions.applicationActions.updateModalStep(1));
    dispatch(actions.applicationActions.updateModalState(true));
  }*/

	const toggleSideMenu = () => {
		console.log("menu active");
		document.getElementById("nav").classList.toggle("active");
	};

	const scrollTo = (ancla) => {
		let x = document.querySelector("#" + ancla);
		if (x) {
			x.scrollIntoView({ block: "start", behavior: "smooth" });
		}
		return false;
	};

	return (
		<>
			<section className="banner">
				<div className="banner-bg">
					<div className={x.join("nav-top ")}>
						{/* <nav className="navbar navbar-expand-lg ">
              <div className="container">
                <Link className="navbar-brand" to="https://silverstonks.io/">
                  <img src={Logo} className="img-fluid" alt="Logo" />
                </Link>
                <button
                  className="navbar-toggler"
                  type="button"
                  onClick={() => dispatch(actions.applicationActions.updateModalState(true))}>
                  <div className="navbar-toggler-icon">
                    <FaBars />
                  </div>
                </button>

                <div
                  className="collapse navbar-collapse"
                  id="navbarSupportedContent">
                  <a className="nav-link text-light" href="https://pancakeswap.finance/swap" target="_blank" rel="noreferrer">
                    BUY SSTX
                  </a>
                  <ul className="navbar-nav ml-auto d-flex justify-content-center align-items-center">
                    <li className="nav-item">
                      {isAuthenticated && walletAddress ? (
                        <div style={{ display: "flex" }}>
                          <CopyToClipboard text={walletAddress}>
                            <div className="copy-area">
                              <button
                                type="button"
                                className="btn btn-outline-success text-light"
                                onClick={() => setText(walletAddress)}>
                                {formatWalletAddress(walletAddress)}
                              </button>
                            </div>
                          </CopyToClipboard>
                          <button
                            className="btn btn-outline-danger text-light ml-3"
                            onClick={() => dispatch(actions.authActions.logOutUser())}>
                            Logout
                          </button>
                        </div>
                      ) : (
                        <button
                          type="button"
                          className="btn btn-outline-success text-light"
                          onClick={() => dispatch(actions.applicationActions.updateModalState(true))}>
                          Log In or Sign Up
                        </button>
                      )}
                    </li>
                  </ul>
                </div>
              </div>
            </nav> */}
						<div className="container-xl">
							<div className="nav-container">
								<div className="nav-brand">
									<div className="navbar-brand">
										<a href="http://silverstonks.io">
											<img
												src={Logo}
												className="img-fluid"
												alt="Logo"
											/>
										</a>
									</div>
								</div>
								<ul className="nav" id="nav">
									<li className="nav-item pt-3 d-flex d-md-none justify-content-end">
										<button
											className="btn btn-clear"
											id="menu-toggle"
											onClick={() => toggleSideMenu()}
										>
											<i className="fas fa-times txt-color-white"></i>
										</button>
									</li>
									<li className="nav-item">
										<a
											href="https://silverstonks.io/about.html"
											className="nav-link"
										>
											ABOUT
										</a>
									</li>
									{/* <li className="nav-item">
                    <a href="https://silverstonks.io/why.html" className="nav-link">WHY</a>
                  </li> */}
									<li className="nav-item">
										<a
											href="https://silverstonks.io/token-sstx.html"
											className="nav-link"
										>
											TOKEN SSTX
										</a>
									</li>
									<li className="nav-item">
										<a
											href="https://silverstonks.io/membership.html"
											className="nav-link"
										>
											MEMBERSHIPS
										</a>
									</li>
									<li className="nav-item">
										<a
											href="https://www.silverstonks.com/"
											className="nav-link"
										>
											SHOP
										</a>
									</li>
									{/* <li className="nav-item">
										<a
											href="https://www.silverbackcity.io/"
											className="nav-link"
										>
											METAVERSE
										</a>
                  </li> */}
									<li className="nav-item dropdown">
										<a
											className="nav-link dropdown-toggle"
											href="/"
											id="navbarDropdown"
											role="button"
											data-toggle="dropdown"
											aria-haspopup="true"
											aria-expanded="false"
										>
											METAVERSE
										</a>
										<div
											className="dropdown-menu "
											aria-labelledby="navbarDropdown"
										>
											<a
												className="dropdown-item nav-link"
												href="https://www.silverbackcity.io/"
												target="_blank"
												rel="noreferrer"
											>
												Silverback City
											</a>
											<a
												className="dropdown-item nav-link"
												href="https://www.silverstonks.io/assets/files/SilverBankRun.pdf"
												target="_blank"
												rel="noreferrer"
											>
												Play 2 Earn
											</a>
											<a
												className="dropdown-item nav-link"
												href="https://propertyholders.io/"
												target="_blank"
												rel="noreferrer"
											>
												Property Holders
											</a>
										</div>
									</li>
									<li className="nav-item dropdown">
										<a
											className="nav-link dropdown-toggle"
											href="/"
											id="navbarDropdown"
											role="button"
											data-toggle="dropdown"
											aria-haspopup="true"
											aria-expanded="false"
										>
											Learn
										</a>
										<div
											className="dropdown-menu "
											aria-labelledby="navbarDropdown"
										>
											<a
												className="dropdown-item nav-link"
												href="https://silverstonks.io/learn-silver-stonks-academy.html"
											>
												Academy
											</a>
											<a
												className="dropdown-item nav-link"
												href="https://silverstonks.io/learn-faq.html"
											>
												FAQ's
											</a>
											<a
												className="dropdown-item nav-link"
												href="https://silverstonks.io/learn-user-guide.html"
											>
												User Guides
											</a>
										</div>
									</li>
									<li
										className="nav-item"
										onClick={() => scrollTo("buy-sstx")}
									>
										<button className="btn btn-outline-bgalpha mb-3 mb-md-0">
											BUY SSTX
										</button>
									</li>
									<li className="nav-item">
										<ul className="navbar-nav ml-auto d-flex justify-content-center align-items-center">
											<li className="nav-item m-0">
												{isAuthenticated &&
												walletAddress ? (
													{
														/*<div style={{ display: "flex" }}>
                          <CopyToClipboard text={walletAddress}>
                            <div className="copy-area">
                              <button
                                type="button"
                                className="btn btn-outline-bgalpha "
                                onClick={() => setText(walletAddress)}>
                                {formatWalletAddress(walletAddress)}
                              </button>
                            </div>
                      </CopyToClipboard>
                        </div>*/
													} && (
														<button
															className="btn btn-outline-danger ml-3 "
															onClick={() =>
																dispatch(
																	actions.authActions.logOutUser()
																)
															}
														>
															Logout
														</button>
													)
												) : (
													<button
														type="button"
														className="btn btn-outline-bgalpha "
														onClick={() =>
															dispatch(
																actions.applicationActions.updateModalState(
																	true
																)
															)
														}
													>
														LOG IN
													</button>
												)}
											</li>
										</ul>
									</li>
								</ul>

								<button
									className="btn btn-clear px-0 d-block d-md-none"
									id="menu-toggle"
									onClick={() => toggleSideMenu()}
								>
									<div className="navbar-toggler-icon">
										<FaBars />
									</div>
								</button>
							</div>
						</div>
					</div>
					<div className="container">
						<div className="banner-content">
							<h2>Silver Stonks Staking</h2>
							<p>Earn additional SSTX through staking.</p>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default Header;
