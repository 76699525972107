import { useDispatch } from "react-redux";
import actions from "../../store/actions";
import MetaMaskLogo from "../../static/images/metamask.svg";
import WalletConnectLogo from "../../static/images/walletconnect.svg";
import { useWeb3Modal, useWeb3ModalAccount } from '@web3modal/ethers5/react'
import { WALLETCONNECT } from "enums/wallet";

const ChooseHowConnect = () => {

    const { address, chainId, isConnected } = useWeb3ModalAccount();


  const dispatch = useDispatch();


  const onClickMetamask = () => {
    if (!window.ethereum) {
      dispatch(actions.applicationActions.updateModalStep(4));
    } else {
      dispatch(actions.blockChainActions.handleMetaMaskConnection());
      dispatch(actions.applicationActions.updateModalState(false));
    }
  };

  const onClickWalletConnect = () => {

  dispatch({
    type: "SET_CONNECTED_WALLET",
    payload: { connectedWallet: WALLETCONNECT },
  });

  };

  return (
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title">Connect Your Wallet</h5>
        <button
          type="button"
          className="close"
          onClick={() =>
            dispatch(actions.applicationActions.updateModalState(false))
          }
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <div className="row">
          <div className="col-12 d-flex justify-content-around my-3 py-2">
            <div
              style={{ cursor: "pointer" }}
              className="text-center blockchain-connect "
              onClick={() => onClickMetamask()}
            >
              <img src={MetaMaskLogo} alt="MetaMask" height={50}></img>
              <p className="pt-2">MetaMask</p>
            </div>
            <div
              style={{ cursor: "pointer" }}
              className="text-center blockchain-connect"
              onClick={() => onClickWalletConnect()}
            >
              <img
                src={WalletConnectLogo}
                alt="WalletConnect"
                height={50}
              ></img>
              <p className="pt-2">WalletConnect</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChooseHowConnect;
